import styled from 'styled-components';
import Page404Image from '~/client/images/404.png';
import { useTranslation } from 'next-i18next';
import { GlobalColors } from '@quotalogic/ui';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Text = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: ${GlobalColors.text.secondary}
`;

const Image = styled.img`
  width: 240px;
  height: 176px;
`;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(locale ? await serverSideTranslations(locale) : {}),
  },
});

export default function Page404() {
  const { t } = useTranslation();
  return (
    <Container>
      <Image src={Page404Image.src} />
      <Text>{t('page404.text')}</Text>
    </Container>
  );
}
